
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showGuestFields: false,
      firstname: '',
      lastname: '',
      email: '',
      guestFirstname: '',
      guestLastname: '',
      guestEmail: '',
      messageHasBeenSent: false,
      isSending: false
    };
  },
  methods: {
    async submit() {
      this.isSending = true;
      const content = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        hasGuest: this.showGuestFields,
        guestFirstname: this.guestFirstname,
        guestLastname: this.guestLastname,
        guestEmail: this.guestEmail
      };

      const emailData = {
        params: {
          adminEmail: this.data.emailAdmin,
          lang: this.$i18n.locale,
          content
        }
      };

      try {
        await this.$axios.post('api/sendmail', null, emailData);
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.guestFirstname = '';
        this.guestLastname = '';
        this.guestEmail = '';
        this.messageHasBeenSent = true;
        this.isSending = false;
      } catch (err) {
        console.log(err);
        this.isSending = false;
      }
    }
  }
};
